.navigation {
    display: none;
}

@media (--snow-tablet) {
    .navigation {
        display: block;
        height: 100%;
    }

    .list {
        display: flex;
        height: 100%;
    }

    .item {
        height: 100%;
    }

    .item + .item {
        margin-left: 4px;
    }

    .action {
        align-items: center;
        background-color: #e81711;
        border: none;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        min-width: 38px;
        outline: none;
        padding: 0;

        &:hover,
        &:focus {
            background-color: #de2622;
        }

        &:empty {
            display: none;
        }
    }

    .actionRedesign {
        border-radius: 8px;
        min-width: 48px;
    }

    .stickedAction {
        background-color: #eef2f6;

        &:hover,
        &:focus {
            background-color: #e6eaf0;
        }
    }
}

@media (--snow-desktop) {
    .action {
        min-width: 58px;
    }

    .actionRedesign {
        min-width: 58px;
        border-radius: 6px;
    }

    .stickedAction {
        min-width: 38px;
    }

    .stickedActionRedesign {
        min-width: 58px;
    }
}
